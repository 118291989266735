import axios from "axios";
const IPURL = "https://api.ipify.org";

let publicIP = {
    getIP: async () => {
        try{
            const res = await axios.get(IPURL);
            return res.data;
        } catch(e) {
            console.warn(e);

            return false;
        }
    }
};

export default publicIP;