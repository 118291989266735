import React from "react";

const UserContext = React.createContext({
  userId: -1,
  name: "Not Available",
  firstName: "N/A",
  initials: "N/A",
  id: "N/A",
  permissions: [],
  username: null,
});

const UsersContext = React.createContext(
  new Map([
    [
      -1,
      {
        name: "Not Available",
        firstName: "N/A",
        initials: "N/A",
        id: "N/A",
        permissions: [],
        username: null,
      },
    ],
  ])
);

const useUsers = () => React.useContext(UsersContext);
const useUser = () => React.useContext(UserContext);

export { UserContext, UsersContext, useUser, useUsers };
