export {default as DB} from "./DBConnect";
export {default as Theme} from "./Theme";
export {default as IP} from "./publicIP";

let isJSON = (str) => {
    try{
        JSON.parse(str);
    } catch(e){
        return false;
    }

    return true;
};

export {isJSON};